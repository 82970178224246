<template>
  <v-dialog v-model="dialog" max-width="800">
    <perseu-card title="Formulário de Espaços Potenciais">
      <template slot="content">
        <v-form ref="form">
          <v-text-field
            autofocus
            label="Nome"
            v-model.trim="potentialSpace.name"
            :rules="[$rules.required]"
          />
          <v-textarea
            counter
            maxlength="1000"
            label="Instruções"
            v-model="potentialSpace.instructions"
          />
          <select-categories
            v-model="potentialSpace.categories"
            label="Categorias"
            :rules="[$rules.requiredArray]"
          />
          <select-lang
            v-model="potentialSpace.lang"
            :rules="[$rules.required]"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    "select-categories": () => import("@/components/Categories/Select"),
  },
  data: () => ({
    potentialSpace: {},
    dialog: false,
  }),
  methods: {
    open(potentialSpaceParam) {
      this.potentialSpace = potentialSpaceParam;
      this.dialog = true;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$emit("saved", this.potentialSpace);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
